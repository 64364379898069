import { dateYmd } from "../../utils/helpers";
import { subDays } from "date-fns";
import {
  SET_DASHBOARD_END_DATE,
  SET_DASHBOARD_PRODUCTS,
  SET_DASHBOARD_START_DATE,
  SET_DASHBOARD_SUMMARIES,
  SET_TIMESERIES_DATA,
  SET_TIMESERIES_END_DATE,
  SET_TIMESERIES_START_DATE
} from "../actions/dashboard";

const initialState = {
  summaries: {},
  products: [],
  timeSeriesData: {
    labels: [],
    datasets: [
      {
        label: "Completed",
        fill: "start",
        data: [0],
        backgroundColor: "rgba(0,123,255,0.1)",
        borderColor: "rgba(0,123,255,1)",
        pointBackgroundColor: "#ffffff",
        pointHoverBackgroundColor: "rgb(0,123,255)",
        borderWidth: 1.5,
        pointRadius: 0,
        pointHoverRadius: 3
      },
      {
        label: "Failed",
        fill: "start",
        data: [0],
        backgroundColor: "rgba(255,65,105,0.1)",
        borderColor: "rgba(255,65,105,1)",
        pointBackgroundColor: "#ffffff",
        pointHoverBackgroundColor: "rgba(255,65,105,1)",
        borderDash: [3, 3],
        borderWidth: 1,
        pointRadius: 0,
        pointHoverRadius: 2,
        pointBorderColor: "rgba(255,65,105,1)"
      }
    ]
  },
  startDate: dateYmd(subDays(new Date(), 7)),
  endDate: dateYmd(new Date()),
  timeSeriesStartDate: dateYmd(subDays(new Date(), 7)),
  timeSeriesEndDate: dateYmd(new Date())
};

export default function dashbaordReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DASHBOARD_SUMMARIES:
      return { ...state, summaries: action.summaries };
    case SET_DASHBOARD_PRODUCTS:
      return { ...state, products: action.products };
    case SET_DASHBOARD_START_DATE:
      return { ...state, startDate: action.startDate };
    case SET_DASHBOARD_END_DATE:
      return { ...state, endDate: action.endDate };
    case SET_TIMESERIES_START_DATE:
      return { ...state, timeSeriesStartDate: action.startDate };
    case SET_TIMESERIES_END_DATE:
      return { ...state, timeSeriesEndDate: action.endDate };
    case SET_TIMESERIES_DATA:
      const { labels, datasets } = action.payload;
      return { ...state, timeSeriesData: { labels, datasets } };
    default:
      return state;
  }
}
