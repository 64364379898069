export default function() {
  return [
    {
      title: "Dashboard",
      to: "/dashboard",
      htmlBefore: '<i class="material-icons">dashboard</i>'
    },
    {
      title: "Transactions",
      htmlBefore: '<i class="material-icons">format_list_numbered_rtl</i>',
      to: "/transactions"
    },
    {
      title: "Airtime",
      htmlBefore: '<i class="material-icons">sim_card</i>',
      to: "/airtimes"
    },

    {
      title: "Wallets",
      htmlBefore: '<i class="material-icons">account_balance_wallet</i>',
      to: "/wallets"
    },

    { 
      title: "Account",
      htmlBefore: '<i class="material-icons">portrait</i>',
      to: "/account-profile"
    }
  ];
}
