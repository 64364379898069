import { clientApi } from "../../axios";
import { loading } from "./loading";

export const SET_DASHBOARD_SUMMARIES = "SET_DASHBOARD_SUMMARIES";

export const SET_DASHBOARD_PRODUCTS = "SET_DASHBOARD_PRODUCTS";

export const SET_DASHBOARD_START_DATE = "SET_DASHBOARD_START_DATE";

export const SET_DASHBOARD_END_DATE = "SET_DASHBOARD_END_DATE";

export const SET_TIMESERIES_START_DATE = "SET_TIMESERIES_START_DATE";

export const SET_TIMESERIES_END_DATE = "SET_TIMESERIES_END_DATE";

export const SET_TIMESERIES_DATA = "SET_TIMESERIES_DATA";

export const setDashboardSummary = summaries => ({
  type: SET_DASHBOARD_SUMMARIES,
  summaries
});

export const setDashboardProducts = products => ({
  type: SET_DASHBOARD_PRODUCTS,
  products
});

export const setDashboardStartDate = startDate => ({
  type: SET_DASHBOARD_START_DATE,
  startDate
});

export const setDashboardEndDate = endDate => ({
  type: SET_DASHBOARD_END_DATE,
  endDate
});

export const setTimeseriesStartDate = startDate => ({
  type: SET_TIMESERIES_START_DATE,
  startDate
});

export const setTimeseriesEndDate = endDate => ({
  type: SET_TIMESERIES_END_DATE,
  endDate
});

export const setTimeSeriesData = payload => ({
  type: SET_TIMESERIES_DATA,
  payload
});

function fetchDashboardSummary(startDate, endDate, partnerId) {
  return clientApi.get(
    `/api/Partners/dashboard/total/percent?StartDate=${startDate}&EndDate=${endDate}&PartnetId=${partnerId}`
  );
}

function fetchDashboardProducts(startDate, endDate, partnerId) {
  return clientApi.get(
    `/api/Partners/dashboard/product/percentage?StartDate=${startDate}&EndDate=${endDate}&PartnetId=${partnerId}`
  );
}

function fetchDashboardTimeseries(timeSeriesStartDate, timeSeriesEndDate, partnerId) {
  return clientApi.get(
    `/api/Partners/dashboard/timeseries?StartDate=${timeSeriesStartDate}&EndDate=${timeSeriesEndDate}&PartnetId=${partnerId}`
  );
}

function computeTimeSeriesData(datasets, seriesData) {
  return datasets.map((d, i) => {
    return {
      ...d,
      data: seriesData.datasets[i].data.length ? seriesData.datasets[i].data : [0],
      label: seriesData.datasets[i].label
    };
  });
}

export function handleDashbaordData() {
  return (dispatch, getState) => {
    const { startDate, endDate, timeSeriesStartDate, timeSeriesEndDate, timeSeriesData } = getState().dashboard;
    const { partnerId } = getState().auth.currentUser;
    dispatch(loading(true));
    Promise.all([
      fetchDashboardSummary(startDate, endDate, partnerId),
      fetchDashboardProducts(startDate, endDate, partnerId),
      fetchDashboardTimeseries(timeSeriesStartDate, timeSeriesEndDate, partnerId)
    ])
      .then(([summaries, products, timeSeries]) => {
        dispatch(loading(false));
        dispatch(setDashboardSummary(summaries.data.data));
        dispatch(setDashboardProducts(products.data.data));
        const { labels, datasets } = timeSeriesData;
        const series = timeSeries.data.data.conversionModel.data;
        dispatch(setTimeSeriesData({ labels: series.labels, datasets: computeTimeSeriesData(datasets, series) }));
      })
      .catch(error => {
        dispatch(loading(false));
        console.log(error);
      });
  };
}

export function handleTimeSeriesData() {
  return async (dispatch, getState) => {
    const { timeSeriesStartDate, timeSeriesEndDate, timeSeriesData} = getState().dashboard;
    const { partnerId } = getState().auth.currentUser;
    const { labels, datasets } = timeSeriesData;
    try {
      dispatch(loading(true));
      const timeSeries = await fetchDashboardTimeseries(timeSeriesStartDate, timeSeriesEndDate, partnerId);
      dispatch(loading(false));
      const series = timeSeries.data.data.conversionModel.data;
      dispatch(setTimeSeriesData({ labels: series.labels, datasets: computeTimeSeriesData(datasets, series) }));
    } catch (error) {
      dispatch(loading(false));
      console.log(error)
    }
   
    
  };
}
