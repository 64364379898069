import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleFetchAirtimeTransactions} from "../../redux/actions/airtimes";
import { naira, transactionDateFormat } from "../../utils/helpers";
import TableList from "../../views/TableList";

import Status from "../partials/Status";
import AirtimeTableFilters from "./AirtimeTableFilters";

const headers = ["Phone Number", "Email","Network", "Amount", "Time", "Status"];

const renderRow = item => {
  return (
    <>
      <td>{item.recipientPhoneNumber} </td>
      <td>{item.partnerUserEmail} </td>
      <td>{item.recipientNetwork} </td>
      <td>{naira(item.amount)}</td>
      <td>{transactionDateFormat(item.initiatedOn)}</td>
      <td>{<Status value={item.status} />}</td>
    </>
  );
};


const AirtimeListTable = () => {
  const dispatch = useDispatch();
  const { result = [], totalRecords, perPage} = useSelector(state => state.airtimes);
  const { partnerId } = useSelector(state => state.auth.currentUser);
  const [filters, setFilters] = useState({});
  
  const getFilterState = filterState => {
    setFilters({ ...filters, ...filterState });
  };

  const getPageRecords = page => {
    const { transactionReference, phoneNumber, networkProvider, transactionStatus } = filters;
    dispatch(
      handleFetchAirtimeTransactions({
        partnerId,
        networkProvider,
        transactionReference,
        transactionStatus,
        phoneNumber,
        currentPage: page
      })
    );
  };

  return (
    <TableList
      headers={headers}
      title={<AirtimeTableFilters filterState={getFilterState} />}
      data={result}
      renderItem={renderRow}
      totalRecords={totalRecords}
      pageSize={perPage}
      fetchPageRecords={page => getPageRecords(page)}
    />
  );
};

export default AirtimeListTable;
